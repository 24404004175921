import * as React from "react"
import Template from "../components/template"

const NewsPage = () => {
  return (
    <Template>
      <main className="max-w-screen-lg mx-auto">Ecco le news</main>
    </Template>
  )
}

export default NewsPage
